import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    return (
      <div className="loader">
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
      </div>
    );
  }
}

export default LogoLoader;



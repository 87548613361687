/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true,  // Change this to false if you don't want Splash screen.
};

// SEO Related settings
const seo = {
  title: 'Madalin\'s Portfolio',
  description:
      'A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.',
  og: {
    title: 'Madalin NITU Portfolio',
    type: 'website',
    url: 'https://madalinnitu.com/',
  },
};

// Home Page
const greeting = {
  title: 'Madalin NITU',
  logo_name: 'MadalinNITU',
  nickname: 'mnitu',
  subTitle:
      'A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.',
  resumeLink: 'https://madlex.s3.eu-west-1.amazonaws.com/Madalin_NITU_CV.pdf',
  portfolio_repository: 'https://github.com/MadalinNitu',
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/MadalinNitu",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: 'Github',
    link: 'https://github.com/MadalinNitu',
    fontAwesomeIcon: 'fa-github',  // Reference
    // https://fontawesome.com/icons/github?style=brands
    backgroundColor: '#181717',  // Reference https://simpleicons.org/?q=github
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/in/madalin-nitu/',
    fontAwesomeIcon: 'fa-linkedin-in',  // Reference
    // https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor:
        '#0077B5',  // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference
  //   https://fontawesome.com/icons/youtube?style=brands backgroundColor:
  //   "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: 'Gmail',
    link: 'mailto:mnitu.constantin@gmail.com',
    fontAwesomeIcon: 'fa-google',  // Reference
    // https://fontawesome.com/icons/google?style=brands
    backgroundColor: '#D14836',  // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference
  //   https://fontawesome.com/icons/twitter?style=brands backgroundColor:
  //   "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/madalinnitu/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference
  //   https://fontawesome.com/icons/facebook-f?style=brands backgroundColor:
  //   "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference
  //   https://fontawesome.com/icons/instagram?style=brands backgroundColor:
  //   "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: 'Infrastructure Engineer',
      fileName: 'InfrastructureEngineerImg',
      skills: [
        '⚡ Design infrastructure in cloud (Azure, AWS, GCP, Heroku, Firebase) or on-premm (VMWare, Proxmox, Vagrant)',
        '⚡ Automate tasks using puppet, ansible or jenkins and integrate CI/CD into projects by creating workflows',
        '⚡ Monitoring infrastructure and applications using Zabbix, Grafana, ELK or TICK',
      ],
      softwareSkills: [
        {
          skillName: 'ELK',
          fontAwesomeClassname: 'logos-elasticsearch',
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          skillName: 'Ansible',
          fontAwesomeClassname: 'simple-icons:ansible',
          style: {
            backgroundColor: 'white',
            color: '#D00000',
          },
        },
        {
          skillName: 'Azure',
          fontAwesomeClassname: 'logos-azure',
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          skillName: 'Linux',
          fontAwesomeClassname: 'logos-linux-tux',
          style: {
            backgroundColor: 'transparent',
            color: '#3776AB',
          },
        },
      ],
    },
    {
      title: 'Full Stack Development',
      fileName: 'FullStackImg',
      skills: [
        '⚡ Building resposive website front end using React-Redux',
        '⚡ Developing mobile applications using Kotlin for android apps',
        '⚡ Creating application backend in Node, Express, Flask, Django, Go, C++',
        '⚡ Developing highly scalable production products',
      ],
      softwareSkills: [
        {
          skillName: 'C++',
          fontAwesomeClassname: 'logos-c-plusplus',
          style: {
            color: '#E34F26',
          },
        },
        {
          skillName: 'Python',
          fontAwesomeClassname: 'logos-python',
          style: {
            color: '#1572B6',
          },
        },
        {
          skillName: 'Sass',
          fontAwesomeClassname: 'simple-icons:sass',
          style: {
            color: '#CC6699',
          },
        },
        {
          skillName: 'Go',
          fontAwesomeClassname: 'logos-go',
          style: {
            color: '#F7DF1E',
          },
        },
        {
          skillName: 'ReactJS',
          fontAwesomeClassname: 'simple-icons:react',
          style: {
            color: '#61DAFB',
          },
        },
        {
          skillName: 'NodeJS',
          fontAwesomeClassname: 'simple-icons:node-dot-js',
          style: {
            color: '#339933',
          },
        },
        {
          skillName: 'Docker',
          fontAwesomeClassname: 'logos-docker',
          style: {
            color: '#CB3837',
          },
        },
        {
          skillName: 'Gatsby',
          fontAwesomeClassname: 'simple-icons:gatsby',
          style: {
            color: '#663399',
          },
        }
      ],
    },
    {
      title: 'Cloud Infra-Architecture',
      fileName: 'CloudInfraImg',
      skills: [
        '⚡ Experience working on multiple cloud platforms',
        '⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases',
        '⚡ Deploying applications on cloud to use on mobile and web devices',
      ],
      softwareSkills: [
        {
          skillName: 'GCP',
          fontAwesomeClassname: 'simple-icons:googlecloud',
          style: {
            color: '#4285F4',
          },
        },
        {
          skillName: 'AWS',
          fontAwesomeClassname: 'simple-icons:amazonaws',
          style: {
            color: '#FF9900',
          },
        },
        {
          skillName: 'Azure',
          fontAwesomeClassname: 'simple-icons:microsoftazure',
          style: {
            color: '#0089D6',
          },
        },
        {
          skillName: 'Firebase',
          fontAwesomeClassname: 'simple-icons:firebase',
          style: {
            color: '#FFCA28',
          },
        },
        {
          skillName: 'PostgreSQL',
          fontAwesomeClassname: 'simple-icons:postgresql',
          style: {
            color: '#336791',
          },
        },
        {
          skillName: 'MongoDB',
          fontAwesomeClassname: 'simple-icons:mongodb',
          style: {
            color: '#47A248',
          },
        },
        {
          skillName: 'Docker',
          fontAwesomeClassname: 'simple-icons:docker',
          style: {
            color: '#1488C6',
          },
        },
        {
          skillName: 'Kubernetes',
          fontAwesomeClassname: 'simple-icons:kubernetes',
          style: {
            color: '#326CE5',
          },
        },
      ],
    },
    {
      title: 'UI/UX Design',
      fileName: 'DesignImg',
      skills: [
        '⚡ Designing highly attractive user interface for mobile and web applications',
        '⚡ Creating the flow of application functionalities to optimize user experience',
        '⚡ Designing SEO protocols and marketing usage',
      ],
      softwareSkills: [
        {
          skillName: 'Adobe XD',
          fontAwesomeClassname: 'simple-icons:adobexd',
          style: {
            color: '#FF2BC2',
          },
        },
        {
          skillName: 'Adobe Illustrator',
          fontAwesomeClassname: 'simple-icons:adobeillustrator',
          style: {
            color: '#FF7C00',
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: 'AZ-900',
      iconifyClassname: 'simple-icons:microsoftazure',
      style: {
        color: '#0089D6',
      },
      profileLink:
          'https://madlex.s3.eu-west-1.amazonaws.com/AZ-900_Microsoft_Certificate.pdf',
    },
    {
      siteName: 'Bachelor',
      iconifyClassname: 'simple-icons:codeforces',
      style: {
        color: '#1F8ACB',
      },
      profileLink: 'https://fmi.unibuc.ro/en/',
    },
    // {
    //     siteName: 'Hackerearth',
    //     iconifyClassname: 'simple-icons:hackerearth',
    //     style: {
    //         color: '#323754',
    //     },
    //     profileLink: 'https://www.hackerearth.com/@ashutosh391',
    // },
    // {
    //     siteName: 'Kaggle',
    //     iconifyClassname: 'simple-icons:kaggle',
    //     style: {
    //         color: '#20BEFF',
    //     },
    //     profileLink: 'https://www.kaggle.com/laymanbrother',
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: 'University of Bucharest',
      subtitle: 'The Faculty of Mathematics and Computer Science',
      logo_path: 'unibuc.png',
      alt_name: 'UNIBUC',
      duration: '2016 - 2019',
      descriptions: [
        '⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.',
        '⚡ Apart from this, I have done courses on Django, Cloud Computing and Full Stack Development.',
      ],
      website_link: 'https://fmi.unibuc.ro/en/',
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: 'AZ-900',
      subtitle: 'Azure Fundamentals',
      logo_path: 'microsoft_logo.png',
      certificate_link:
          'https://madlex.s3.eu-west-1.amazonaws.com/AZ-900_Microsoft_Certificate.pdf',
      alt_name: 'Stanford University',
      color_code: '#8C151599',
    },
    {
      title: 'Windows C++ Training',
      subtitle: 'Bitdefender Windows C++ Training',
      logo_path: 'bitdefender.png',
      certificate_link: 'https://www.bitdefender.com/',
      alt_name: 'backend.cpp',
      color_code: '#00000099',
    },
    // {
    //     title: 'ML on GCP',
    //     subtitle: '- GCP Training',
    //     logo_path: 'google_logo.png',
    //     certificate_link:
    //     'https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647',
    //     alt_name: 'Google',
    //     color_code: '#0C9D5899',
    // },
    // {
    //     title: 'Data Science',
    //     subtitle: '- Alex Aklson',
    //     logo_path: 'ibm_logo.png',
    //     certificate_link:
    //     'https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5',
    //     alt_name: 'IBM',
    //     color_code: '#1F70C199',
    // },
    // {
    //     title: 'Big Data',
    //     subtitle: '- Kim Akers',
    //     logo_path: 'microsoft_logo.png',
    //     certificate_link:
    //     'https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view',
    //     alt_name: 'Microsoft',
    //     color_code: '#D83B0199',
    // },
    // {
    //     title: 'Advanced Data Science',
    //     subtitle: '- Romeo Kienzler',
    //     logo_path: 'ibm_logo.png',
    //     certificate_link:
    //     'https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH',
    //     alt_name: 'IBM',
    //     color_code: '#1F70C199',
    // },
    // {
    //     title: 'Advanced ML on GCP',
    //     subtitle: '- GCP Training',
    //     logo_path: 'google_logo.png',
    //     certificate_link:
    //     'https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV',
    //     alt_name: 'Google',
    //     color_code: '#0C9D5899',
    // },
    // {
    //     title: 'DL on Tensorflow',
    //     subtitle: '- Laurence Moroney',
    //     logo_path: 'deeplearning_ai_logo.png',
    //     certificate_link:
    //     'https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8',
    //     alt_name: 'deeplearning.ai',
    //     color_code: '#00000099',
    // },
    // {
    //     title: 'Fullstack Development',
    //     subtitle: '- Jogesh Muppala',
    //     logo_path: 'coursera_logo.png',
    //     certificate_link:
    //     'https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA',
    //     alt_name: 'Coursera',
    //     color_code: '#2A73CC',
    // },
    // {
    //     title: 'Kuberenetes on GCP',
    //     subtitle: '- Qwiklabs',
    //     logo_path: 'gcp_logo.png',
    //     certificate_link:
    //     'https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750',
    //     alt_name: 'GCP',
    //     color_code: '#4285F499',
    // },
    // {
    //     title: 'Cryptography',
    //     subtitle: '- Saurabh Mukhopadhyay',
    //     logo_path: 'nptel_logo.png',
    //     certificate_link:
    //     'https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_',
    //     alt_name: 'NPTEL',
    //     color_code: '#FFBB0099',
    // },
    // {
    //     title: 'Cloud Architecture',
    //     subtitle: '- Qwiklabs',
    //     logo_path: 'gcp_logo.png',
    //     certificate_link:
    //     'https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2',
    //     alt_name: 'GCP',
    //     color_code: '#4285F499',
    // },
  ],
};

// Experience Page
const experience = {
  title: 'Experience',
  subtitle: 'Work, Internship and Volunteership',
  description:
      'I have worked with many evolving corporations as Embeded and Infrastructure Engineer, Designer and Software Architect. I have also worked with some well established companies mostly as Software Trainer. I love organising events and that is why I am also involved with many opensource communities as a representative.',
  header_image_path: 'experience.svg',
  sections: [
    {
      title: 'Work',
      experiences: [
        {
          title: 'Infrastructure Engineer',
          company: 'Bitdefender S.R.L',
          company_url: 'https://bitdefender.com/',
          logo_path: 'bitdefender.png',
          duration: 'Sept 2021 - PRESENT',
          location: 'Bucharest',
          description: [
            '⚡ Delivery high secure and scalable virtual machine to clients.',
            '⚡ Automation infrastructure with foreman and puppet as well as using ansible.',
            '⚡ Monitoring infrastructure using zabbix and grafana tools along with ELK and TICK stacks.',
            '⚡ Provisioning virtual resources using on-prem (VMWare and proxmox) and cloud (AWS, Azure, GCP, Hetzner) infrastructure.',
            '⚡ Sustaining email high-scalable arhitecture.',
            '⚡ Mentaining high-scalable DNS arhitecture.',
            '⚡ Automation arhitecture tasks like generating SSL certifications and other tasks.',
            '⚡ Deploy containers arhitecture.'
          ],
          color: '#fc1f20',
        },
        {
          title: 'R&D Embedded C++ Developer',
          company: 'Keysight S.R.L (IXIA)',
          company_url:
              'https://www.keysight.com/zz/en/cmp/2020/network-visibility-network-test.html',
          logo_path: 'keysight-logo.svg',
          duration: 'Apr 2020 - Sept 2021',
          location: 'Bucharest',
          description: [
            '⚡ Implementing features on production tools using C++, Rust and Python.',
            '⚡ Splitting old monolithic application into microservices',
            '⚡ Creating security patches and bugfixes to improve performance on devices',
            '⚡ Developing new extensions for internal sandbox generator',
            '⚡ Solving hardware like issues on physical products.',
            '⚡ Maintaining multiple Jenkins production pipelines for building images as well as CI/CD development.',
          ],
          color: '#9b1578',
        },
        {
          title: 'Devops Engineer',
          company: 'Bitdefender S.R.L',
          company_url: 'https://bitdefender.com/',
          logo_path: 'bitdefender.png',
          duration: 'May 2018 - Apr 2020',
          location: 'Bucharest',
          description: [
            '⚡ Troubleshooting Linux and Windows based systems, networking issues and software (both OS and application level).',
            '⚡ Ensuring systems are kept in required configuration profile, including: maintenance, changes as required by the evolution of production environments.',
            '⚡ Maintaining cloud and on-premise platforms automating build and release pipelines.',
            '⚡ Performing specific failure recovery procedures for managed systems and applications',
            '⚡ In charge of implementing SSO with Azure AD and Federation Services, as well as maintaining the entire infrastructure periodically (including the endpoints).',
            '⚡ Developed a serverless wrapper which scales the requests of users.',
            '⚡ Involvement in the discussion with the clients about new features, requests or enhancements on projects'
          ],
          color: '#0879bf',
        },

      ],
    },
    {
      title: 'Internships',
      experiences: [
        {
          title: 'Software Developer Intern',
          company: 'Bitdefender S.R.L',
          company_url: 'https://www.bitdefender.com/',
          logo_path: 'bitdefender.png',
          duration: 'Mar 2018 - May 2018',
          location: 'Bucharest',
          description:
              'I have worked on projects of Business application department. At the end of internship, I had created model deployed on cloud AWS EC2 and Azure with the use of web applications, ElasticSearch and PostgreSQL same as on-prem infrastructure.',
          color: '#ee3c26',
        },
      ],
    },
    {
      title: 'Trainer',
      experiences: [
        {
          title: 'Devops Trainer',
          company: 'Telacad S.R.L',
          company_url: 'https://www.telacad.ro',
          logo_path: 'telecom-academy-logo.png',
          duration: 'Apr 2021 - PRESENT',
          location: 'Bucharest',
          description: 'Mentoring students in their devops career path.',
          color: '#D83B01',
        },
        {
          title: 'Python Trainer',
          company: 'Software Development Academy S.R.L',
          company_url: 'https://sdacademy.ro/',
          logo_path: 'sda-logo.svg',
          duration: 'Nov 2020 - Apr 2021',
          location: 'Bucharest',
          description: 'Teacher Python with latest tehnology and methodology.',
          color: '#4285F4',
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: 'Projects',
  description:
      'My projects makes use of vast variety of latest technology tools. My best experience is to create backend projects and deploy them to web applications using cloud infrastructure.',
  avatar_image_path: 'projects_image.svg',
};

const publicationsHeader = {
  title: 'Publications',
  description:
      'I have worked on and published a few research papers and publications of my own.',
  avatar_image_path: 'projects_image.svg',
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: 'Contact Me',
    profile_image_path: 'mnitu_profile.jpg',
    description:
        'I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with website, mobile applications, deploying or creating IT infrastructure (DNS, Storage, Virtualization, Mail, AD etc).',
  },
  blogSection: {
    title: 'Blogs',
    subtitle:
        'For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.',
    link: 'https://howto.madalinnitu.com',
    avatar_image_path: 'blogs_image.svg',
  },
  addressSection: {
    title: 'Address',
    subtitle: 'Bucharest, Romanian',
    avatar_image_path: 'address_image.svg',
    location_map_link: 'https://goo.gl/maps/smq4M66seSu3KC2D6',
  },
  phoneSection: {
    title: 'Phone Number',
    subtitle: '+40 0764371912',
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
import './ContactComponent.css';

import React, {Component} from 'react';
import {Fade} from 'react-reveal';

import Button from '../../components/button/Button';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import TextField from '../../components/textfield/TextField';
import TopButton from '../../components/topButton/TopButton';
import {contactPageData, greeting} from '../../portfolio.js';

import AddressImg from './AddressImg';
import BlogsImg from './BlogsImg';

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

const onClickForm = () => {
  const name = document.getElementsByName('Name')[0].value;
  const email = document.getElementsByName('Email')[0].value;
  const phone = document.getElementsByName('Phone')[0].value;
  const message = document.getElementsByName('Message')[0].value;
  console.log(name, email, phone, message);

  const data = {
    contact: {
      name: name,
      email: email,
      phone: phone,
      message: message,
      origin: 'www.madalinnitu.ro',
      company: 'Madalin NITU'
    }
  };
  fetch('https://api.madalinnitu.com/api/contacts', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
};

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className='contact-main'>
        <Header theme={
      theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  src={require(`../../assests/images/${
      ContactData['profile_image_path']}`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {ContactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {ContactData["description"]}
                </p>
                <SocialMedia theme={theme} />
                <div className="resume-btn-div">
                  <Button
                    text="See My Resume"
                    newTab={true}
                    href={greeting.resumeLink}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">
            <div className="blog-heading-div">
              <div className="blog-heading-text-div">
                <h1 className="blog-heading-text" style={{ color: theme.text }}>
                  {blogSection["title"]}
                </h1>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {blogSection["subtitle"]}
                </p>
                <div className="blogsite-btn-div">
                  <Button
                    text="Visit My Blogsite"
                    newTab={true}
                    href={blogSection.link}
                    theme={theme}
                  />
                </div>
              </div>
              <div className="blog-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${
      blogSection['avatar_image_path']}`)}
											alt=""
										/> */}
                <BlogsImg theme={theme} />
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">
            <div className="address-heading-div">
              <div className="contact-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${
      addressSection['avatar_image_path']}`)}
											alt=""
										/> */}
                <AddressImg theme={theme} />
              </div>
              <div className="address-heading-text-div">
                <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {addressSection["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {addressSection["subtitle"]}
                </p>
                <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {phoneSection["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {phoneSection["subtitle"]}
                </p>
                <div className="address-btn-div">
                  <Button
                    text="Visit on Google Maps"
                    newTab={true}
                    href={addressSection.location_map_link}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">
            <div className="form-heading-div">
              <div className="form-heading-text-div">
                <h1 className="form-heading-text" style={{ color: theme.text }}>
                Let me know your thoughts
                </h1>
                <p
                  className="form-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  Do you want to work together? Do you need help? Please send me a notification!
                </p>
                <div className="form-div">
                  <TextField 
                    className="input-wrap" 
                    theme={theme}
                    fieldName="Name"
                  />
                  <TextField 
                    className="input-wrap" 
                    theme={theme}
                    fieldName="Email"
                  />
                  <TextField 
                    className="input-wrap" 
                    theme={theme}
                    fieldName="Phone"
                  />
                  <TextField 
                    className="input-wrap" 
                    theme={theme}
                    fieldName="Message"
                    isTextarea={true}
                  />
                  <Button
                    className="form-btn-div"
                    text="Send"
                    onClick={onClickForm}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>

        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Contact;
